// Tecnocontrol colors

// primary

$light-primary: #1d1a55;
$light-primary-contrast: #ffffff;

$dark-primary: #4238ff;
$dark-primary-contrast: #ffffff;

// accent/secondary

$light-accent: #009ed7;
$light-accent-contrast: #ffffff;

$dark-accent: #009ed7;
$dark-accent-contrast: #ffffff;

// common

$page-background: #1d1a552f;
